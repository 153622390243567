<div class="course-exercise-answer-datatable-wrapper">
  <table class="course-exercise-answer-datatable">
    <thead class="course-exercise-answer-datatable-thead">
      <tr>
        <th (click)="handleSortClick('username')">
          <span>
            {{ "RANKING.TABLE.NAME" | translate }}
          </span>

          <icon [name]="sortOrderIcon('username')" />
        </th>

        <th (click)="handleSortClick('filename')">
          <span>
            {{ "COURSES.COURSE.EXERCISE.ANSWERS.FILENAME" | translate }}
          </span>

          <icon [name]="sortOrderIcon('filename')" />
        </th>

        <th (click)="handleSortClick('uploadedAt')">
          <span>
            {{ "COURSES.COURSE.EXERCISE.ANSWERS.UPLOADED_AT" | translate }}
          </span>

          <icon [name]="sortOrderIcon('uploadedAt')" />
        </th>

        <th>
          <button
            *ngIf="!bulkLoader; else loaderSpinner"
            class="btn-gray-50 bulk-download"
            [disabled]="actualAnswerId"
            (click)="handleBulkDownloadClick()"
          >
            <icon set="uil" name="download-alt" />
            <span>
              {{ "GLOBAL.DOWNLOAD_ALL" | translate }}
            </span>
          </button>
        </th>
      </tr>
    </thead>

    <tbody class="course-exercise-answer-datatable-tbody">
      <ng-container *ngFor="let answer of answers">
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <round-badge size="large">
                <icon name="user-outline" />
              </round-badge>

              <div class="ml-3 text-left">
                <div [tooltip]="answer.user.email">{{ answer.user.name }}</div>
              </div>
            </div>
          </td>

          <td>
            <span>{{ answer.filename }}</span>
          </td>

          <td>
            <span>{{ answer.uploaded_at | date }}</span>
          </td>

          <td>
            <div class="datatable-actions">
              <ng-container
                *ngIf="answer.id !== actualAnswerId; else loaderSpinner"
              >
                <round-button
                  class="text-color-primary-900"
                  set="uil"
                  icon="download-alt"
                  [disabled]="bulkLoader"
                  [tooltip]="
                    'COURSES.COURSE.EXERCISE.ANSWERS.DOWNLOAD' | translate
                  "
                  (action)="handleDownloadClick(answer)"
                />
              </ng-container>

              <round-button
                class="success"
                set="uil"
                icon="check-circle"
                [tooltip]="'COURSES.COURSE.EXERCISE.ANSWERS.PASS' | translate"
                [isActive]="isPassed(answer)"
                [disabled]="isPassed(answer)"
                (action)="handlePassAnswerClick(answer)"
              />

              <round-button
                class="warning"
                icon="cancel-outline"
                [tooltip]="'COURSES.COURSE.EXERCISE.ANSWERS.FAIL' | translate"
                [isActive]="isFailed(answer)"
                [disabled]="isFailed(answer)"
                (action)="handleFailAnswerClick(answer)"
              />
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #loaderSpinner>
  <div
    class="loader-wrapper d-flex justify-content-start align-items-center pl-3"
  >
    <div style="position: relative">
      <loading-spinner></loading-spinner>
    </div>
    <div class="tooltip">
      {{ "COURSES.COURSE.EXERCISE.ANSWERS.PREPARING" | translate }}
    </div>
  </div>
</ng-template>
