<modal-overlay>
  <modal-box class="modal-container">
    <student-flagged-exercises-list-table
      [helpRequested]="data"
      [topicName]="modalTitle"
    />

    <div class="d-flex justify-content-end mt-4">
      <button class="button btn-primary-900" (click)="handleCloseClick()">
        {{ "GLOBAL.CANCEL_BUTTON" | translate }}
      </button>
    </div>
  </modal-box>
</modal-overlay>
