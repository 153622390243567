import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RunningExerciseData } from "../../types/running-exercise";
import * as fromCourse from "../../../store/actions/course.actions";
import { Store } from "@ngrx/store";
import * as fromUIStore from "../../../store/reducers/ui.reducer";
import {
  ConfirmationConfirmButtonColor,
  ConfirmationModalIcon,
} from "../../types/confirmation-modal";

@Component({
  selector: "navbar-exit-exercise-button",
  templateUrl: "./navbar-exit-exercise-button.component.html",
  styleUrls: ["./navbar-exit-exercise-button.component.scss"],
})
export class NavbarExitExerciseButtonComponent {
  @Input() confirmable: boolean = false;
  @Input() runningExercise: RunningExerciseData;
  @Input() confirmationMessage = "";
  @Input() confirmationTitle = "";
  @Input() confirmButtonText = "";
  @Input() cancelButtonText = "";
  @Input() tooltipMessage = "";
  @Input() icon: ConfirmationModalIcon = "none";
  @Input() confirmButtonColor: ConfirmationConfirmButtonColor;
  @Input() quizNavbar: boolean = false;

  @Output() stopAndExitClickEvent = new EventEmitter<void>();
  @Output() onlyExitClickEvent = new EventEmitter<void>();

  constructor(private readonly store: Store<{ ui: fromUIStore.UIState }>) {}

  handleExitFromExerciseClick(): void {
    if (this.canStopExercise()) {
      this.stop(this.runningExercise);
      this.notifyStopAndExitClickEvent();

      return;
    }

    this.notifyOnlyExitClickEvent();
  }

  private notifyStopAndExitClickEvent(): void {
    this.stopAndExitClickEvent.emit();
  }

  private notifyOnlyExitClickEvent(): void {
    this.onlyExitClickEvent.emit();
  }

  private stop(running: RunningExerciseData): void {
    if (running.group_id) {
      this.stopExerciseForStudent(running);

      return;
    }

    this.stopExerciseForTeacher(running);
  }

  private stopExerciseForTeacher(exercise: RunningExerciseData): void {
    this.store.dispatch(
      new fromCourse.StopTeacherExercise({
        CourseId: exercise.course_id,
        TopicId: exercise.topic_id,
        ExerciseId: exercise.id,
        GroupId: null,
      }),
    );
  }

  private stopExerciseForStudent(exercise: RunningExerciseData): void {
    if (!this.quizNavbar) {
      this.store.dispatch(
        new fromCourse.StopExercise({
          CourseId: exercise.course_id,
          TopicId: exercise.topic_id,
          ExerciseId: exercise.id,
          GroupId: exercise.group_id,
        }),
      );
    }
  }

  private canStopExercise(): boolean {
    return !!this.runningExercise;
  }
}
