<div class="flagged-container">
  <div
    class="flagged-container__header d-flex flex-row justify-content-between align-items-center mr-5 ml-5"
  >
    <p class="flagged-container__subtitle">
      {{ "COURSES.COURSE.STUDENT_SUBMISSIONS" | translate }}
    </p>
    <badge
      set="uil"
      icon="map-marker-question"
      theme="hwarning"
      tooltip="COURSES.COURSE.SUBMISSIONS_COURSE_COUNT"
    >
      <span class="ml-3">{{ flaggedExercisesCount }}</span>
    </badge>
  </div>
  <div
    class="flagged-container__subheader d-flex justify-content-start align-items-center"
  >
    <label class="flagged-container__subtitle ml-5">
      {{ "COURSES.COURSE.TOPIC/EXERCISE" | translate | uppercase }}
    </label>
  </div>
  <div *ngIf="topics.length; else noTopics" class="flagged-container__topics">
    <ng-container *ngFor="let topic of topics">
      <div
        class="flagged-container__topic flagged-container__row d-flex justify-content-between align-items-center"
        [ngClass]="{
          'flagged-container__exercise-open':
            openExercisesList && topic.id === topicId
        }"
        (click)="handleOpenExercises(topic.id)"
      >
        <p class="w-75 ml-5">
          {{ topic.name }}
        </p>
        <div class="d-flex flex-row justify-content-center align-items-center">
          <badge
            set="uil"
            icon="map-marker-question"
            theme="hwarning"
            [small]="true"
            tooltip="COURSES.COURSE.SUBMISSIONS_TOPIC_COUNT"
          >
            <span class="ml-3">{{ topic.report_count }}</span>
          </badge>

          <div
            class="pl-3 mr-3"
            [ngClass]="{
              'flagged-container__icon-open':
                openExercisesList && topic.id === topicId
            }"
          >
            <icon
              class="flagged-container__icon"
              [ngClass]="{
                'flagged-container__icon-off':
                  openExercisesList && topic.id === topicId
              }"
              set="uil"
              name="angle-down"
              [rotate]="
                openExercisesList && topic.id === topicId ? '180' : null
              "
            />
          </div>
        </div>
      </div>
      <div
        class="flagged-container__exercises"
        *ngIf="openExercisesList && topic.id === topicId"
      >
        <ng-container *ngFor="let exercise of topic.exercises">
          <div
            class="flagged-container__exercise flagged-container__row d-flex justify-content-between align-items-center"
          >
            <p
              class="w-75 ml-5 mr-3 flagged-container__name"
              [tooltip]="exercise.name"
            >
              {{ exercise.name }}
            </p>
            <div
              class="d-flex flex-row justify-content-center mr-5 flagged-container__badge"
            >
              <badge
                set="uil"
                icon="map-marker-question"
                theme="hdifficulty"
                [small]="true"
                [hover]="true"
                (click)="handleOpenModal(exercise.id)"
              >
                <span class="ml-3">{{ exercise.report_count }}</span>
              </badge>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noTopics>
  <p class="no-data">
    {{ "GLOBAL.NO_DATA_TO_SHOW" | translate }}
  </p>
</ng-template>
