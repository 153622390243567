import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { FlaggedExercise } from "src/app/shared/interfaces/flagged-exercises";

export const coursePrefix = "[Flagged Exercise History List]";

const flaggedExerciseHistoryListActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const flaggedExerciseHistoryListLoad = createAction(
  flaggedExerciseHistoryListActions.request,
  props<{
    courseId: string;
    groupId: string;
  }>(),
);

export const flaggedExerciseHistoryListLoadSuccess = createAction(
  flaggedExerciseHistoryListActions.requestActiveSuccess,
  props<{ exercises: FlaggedExercise[] }>(),
);

export const flaggedExerciseHistoryListFailure = createAction(
  flaggedExerciseHistoryListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
