import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "booleanString",
})
export class BooleanToStringPipe implements PipeTransform {
  transform(value: boolean | string): string {
    switch (value) {
      case true:
        return "QUIZ.EXERCISE.BOOLEAN_TRUE";
      case false:
        return "QUIZ.EXERCISE.BOOLEAN_FALSE";
      default:
        return value;
    }
  }
}
