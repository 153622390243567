import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EditorOptions } from "src/app/modules/ide/types/editor-options";
import * as monaco from "monaco-editor";

@Component({
  selector: "exercise-resolve",
  templateUrl: "./exercise-resolve.component.html",
  styleUrls: ["./exercise-resolve.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExerciseResolveComponent {
  @Input() answer: string;
  @Input() position: "start" | "end" = "start";

  isExerciseSolution: boolean = false;
  editorOptions: EditorOptions = {
    theme: "vs-dark",
    language: "plaintext",
    automaticLayout: true,
    readOnly: true,
    minimap: { enabled: false },
  };

  editor: monaco.editor.IStandaloneCodeEditor | undefined;

  copyCode(): void {
    navigator.clipboard.writeText(this.answer);
  }

  onEditorInit(editorInstance: monaco.editor.IStandaloneCodeEditor): void {
    this.editor = editorInstance;

    editorInstance.addAction({
      id: "copy-all",
      label: "Copy all",
      contextMenuGroupId: "clipboard",
      contextMenuOrder: 1.5,
      run: () => {
        if (this.editor) {
          navigator.clipboard.writeText(this.editor.getValue());
        }
      },
    });
  }

  viewExerciseSolution(): void {
    this.isExerciseSolution = !this.isExerciseSolution;
  }
}
