import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { MappedHelpRequests } from "src/app/shared/interfaces/mapped-help-requests";

@Component({
  selector: "flagged-exercises-modal",
  templateUrl: "./flagged-exercises-modal.component.html",
  styleUrls: ["./flagged-exercises-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlaggedExercisesModalComponent extends ModalComponent<
  MappedHelpRequests[]
> {
  handleCloseClick(): void {
    this.close.emit("close");
  }
}
