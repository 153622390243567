import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MAX_POINTS_PER_EXERCISE } from "src/app/modules/quiz/components/quiz-form/quiz-form.component";
import {
  QuizSolutionItem,
  QuizSolutionsListResults,
} from "src/app/modules/quiz/interfaces/quiz-solutions-list-results";
import { Guid } from "src/app/shared/types/guid";

@Component({
  selector: "quiz-history-modal-item",
  templateUrl: "./quiz-history-modal-item.component.html",
  styleUrls: [
    "./quiz-history-modal-item.component.scss",
    "../../../../../modules/quiz/components/quiz-form/quiz-form.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizHistoryModalItemComponent {
  @Input() item: QuizSolutionItem;
  @Input() list: QuizSolutionsListResults;
  @Input() courseId: Guid;
  @Input() isTeacher: boolean;
  @Input() activeGroup: Guid;
  @Input() exerciseId: Guid;

  maxPoints = MAX_POINTS_PER_EXERCISE;

  isBoolean(questionContent: string | boolean): boolean {
    return typeof questionContent === "boolean";
  }
}
