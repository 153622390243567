<modal-overlay>
  <modal-box>
    <div class="quiz-solve-modal-body row no-gutters">
      <div class="col-12 congrats-title d-flex flex-column align-items-center">
        <div
          class="round-icon"
          [ngClass]="{
            'round-icon--success':
              data.result.quiz_points >= data.request.quiz.pass_mark
          }"
        >
          <ng-container
            *ngIf="
              data.result.quiz_points >= data.request.quiz.pass_mark;
              else iconFail
            "
          >
            <icon
              class="title-icon title-icon--success"
              set="uil"
              name="check"
            ></icon>
          </ng-container>
          <ng-template #iconFail>
            <icon class="title-icon" name="cancel-outline"></icon>
          </ng-template>
        </div>
        <h5 class="heading-4 mt-3" *ngIf="data.result">
          {{
            (data.result.quiz_points >= data.request.quiz.pass_mark
              ? "QUIZ.EXERCISE.QUIZ_SUCCESS"
              : "QUIZ.EXERCISE.QUIZ_FAILED"
            ) | translate
          }}
        </h5>
      </div>

      <div
        class="col-12 d-flex justify-content-center mt-4"
        *ngIf="data.result"
      >
        <div class="points-progress">
          <div class="required-points">
            <div
              class="required-points-label mini-label"
              [style.width]="(progressFlow$ | async) ? points_percentage : '0%'"
            >
              <span class="label-text">
                {{ (progressFlow$ | async) ? points_percentage : "0%" }}
              </span>
            </div>
            <div
              class="required-points-value"
              [style.width]="(progressFlow$ | async) ? points_percentage : '0%'"
            ></div>
          </div>

          <div
            class="earned-points"
            [style.width]="(progressFlow$ | async) ? '100%' : '0%'"
          >
            <div
              class="required-percentage"
              [style.width]="thresholdPercentage + '%'"
            ></div>
            <div
              [ngClass]="
                data.result.quiz_points >= data.request.quiz.pass_mark &&
                (progressFlow$ | async)
                  ? 'earned-points-value earned-points-value--passed'
                  : 'earned-points-value'
              "
              [style.width]="(progressFlow$ | async) ? points_percentage : '0%'"
            ></div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column justify-content-start mt-4">
        <p class="earned-points-desc mb-2">
          {{ "QUIZ.EXERCISE.POINTS_EARNED" | translate }}:
          {{ data.result.quiz_points }}/{{ data.request.quiz.points_max }}
        </p>
        <p class="earned-points-desc">
          {{ "QUIZ.QUIZ_THRESHOLD_MODAL" | translate }}:
          {{ thresholdPercentage }}%
        </p>
      </div>

      <button class="btn-primary-900 w-100 mt-5" (click)="closeConfirm()">
        {{ "COURSES.COURSE.BACK_TO_COURSE" | translate }}
      </button>
    </div>
  </modal-box>
</modal-overlay>
