import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StudentRatingSystemGradeEntity } from "../interfaces/student-rating-system-grade-entity";
import { StudentRatingSystemFilterFormData } from "../models/student-rating-system-filter-form-data";
import { StudentRatingSystemRecordValue } from "../interfaces/student-rating-system-record-value";
import { map } from "rxjs/operators";
import { GradeOperator } from "../../../../../shared/enums/grade-operator";
import { isNil } from "lodash-es";
import { StudentPassCriteriaApi } from "src/app/shared/interfaces/student-pass-criteria-api";
import { PassCriteriaType } from "src/app/shared/enums/pass-criteria-type";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Injectable()
export class StudentRatingSystemFormService {
  private records: Map<number, StudentRatingSystemRecordValue> = new Map([
    [
      6,
      {
        label: "6",
        value: 90,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        checkboxAvailable: true,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      5.5,
      {
        label: "5.5",
        value: 85,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        checkboxAvailable: true,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      5,
      {
        label: "5",
        value: 80,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      4.5,
      {
        label: "4.5",
        value: 75,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      4,
      {
        label: "4",
        value: 70,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      3.5,
      {
        label: "3.5",
        value: 65,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      3,
      {
        label: "3",
        value: 60,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      2.5,
      {
        label: "2.5",
        value: 55,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      2,
      {
        label: "2",
        value: 50,
        prefix: GradeOperator.GREATER_OR_EQUAL,
        isVisible: true,
        isActive: true,
      },
    ],
    [
      1,
      {
        label: "1",
        value: 50,
        prefix: GradeOperator.LESS_THAN,
        checkboxAvailable: true,
        isVisible: true,
        isActive: true,
      },
    ],
  ]);

  private readonly copy: Map<number, StudentRatingSystemRecordValue> = new Map(
    structuredClone(this.records),
  );

  private readonly entities$ = new BehaviorSubject<
    StudentRatingSystemGradeEntity[]
  >(this.buildEntities());
  private readonly data$ = new BehaviorSubject<
    StudentRatingSystemGradeEntity[]
  >([]);

  save(): void {
    this.data$.next(this.buildEntities());
  }

  update(data: StudentRatingSystemFilterFormData): void {
    const { grades } = data;

    grades.forEach((grade) => {
      const record: StudentRatingSystemRecordValue = this.records.get(grade.id);

      record.value = grade.range;
      record.prefix = grade.prefix;
      record.isActive = grade.active;
    });

    this.entities$.next(this.buildEntities());
  }

  get data(): Observable<StudentRatingSystemGradeEntity[]> {
    return this.data$;
  }

  clear(): void {
    this.data$.next([]);
  }

  showHalfGrades(): void {
    this.records.forEach(
      (record: StudentRatingSystemRecordValue, key: number) => {
        if (Number.isInteger(key)) {
          return;
        }

        record.isVisible = true;
      },
    );
  }

  hideHalfGrades(): void {
    this.records.forEach(
      (record: StudentRatingSystemRecordValue, key: number) => {
        if (Number.isInteger(key)) {
          return;
        }

        record.isVisible = false;
      },
    );
  }

  fetchAll(): Observable<StudentRatingSystemGradeEntity[]> {
    return this.entities$.asObservable();
  }

  fetchByKey(key: number): StudentRatingSystemGradeEntity {
    const entry = this.records.get(key);

    if (isNil(entry)) {
      throw new Error("Entry not found.");
    }

    return {
      grade: key,
      label: entry.label,
      value: entry.value,
      prefix: entry.prefix,
      isVisible: entry.isVisible,
      isActive: entry.isActive,
      checkboxAvailable: entry.checkboxAvailable,
    };
  }

  calculateTotalPoints(statistic: StudentStatElement): number {
    return statistic.topics.reduce((totalPoints, topic) => {
      return (
        totalPoints +
        topic.exercises.reduce(
          (points, exercise) => points + exercise.points,
          0,
        )
      );
    }, 0);
  }

  calculateMaxPoints(statistic: StudentStatElement): number {
    return statistic.topics.reduce((totalPoints, topic) => {
      return (
        totalPoints +
        topic.exercises.reduce(
          (max_points, exercise) => max_points + exercise.max_points,
          0,
        )
      );
    }, 0);
  }

  calculateTotalPercentage(passed: number, total: number): number {
    return total === 0 ? 0 : Math.floor((passed / total) * 100);
  }

  calculateGradeByPercentage(percentage: number): Observable<number> {
    return this.data$.pipe(
      map((grades: StudentRatingSystemGradeEntity[]) =>
        grades.find((grade) => {
          if (
            grade.prefix === GradeOperator.LESS_THAN &&
            percentage < grade.value &&
            grade.isActive
          ) {
            return percentage < grade.value;
          }

          if (
            grade.prefix === GradeOperator.GREATER_OR_EQUAL &&
            percentage >= grade.value &&
            grade.isActive
          ) {
            return percentage >= grade.value;
          }
          if (grade.isActive) {
            return percentage >= grade.value;
          }
        }),
      ),
      map((found: StudentRatingSystemGradeEntity) => found?.grade),
    );
  }

  is1To6RatingSystem(): boolean {
    const one = this.fetchByKey(1);

    return one.isActive;
  }

  buildCriteriaForApiUpdate(
    criteriaType: PassCriteriaType,
  ): StudentPassCriteriaApi {
    return {
      criteria_type: criteriaType,
      half_grades_visible: Array.from(this.records.values()).every(
        (entry) => entry.isVisible,
      ),
      grades: Array.from(this.records.entries()).map(([key, entry]) => ({
        grade: key,
        percent: entry.value,
        operator: entry.prefix,
        active: entry.isActive,
      })),
    };
  }

  updateCriteriaFromApiResponse(criteria: StudentPassCriteriaApi) {
    const { grades, half_grades_visible, criteria_type } = criteria;
    const formValueGrades: StudentRatingSystemFilterFormData = {
      grades: grades.map((gradeEntity) => {
        const {
          grade: id,
          active,
          percent: range,
          operator: prefix,
        } = gradeEntity;
        return {
          id,
          active,
          range,
          prefix,
        };
      }),
      pass_criteria: criteria_type,
    };

    if (half_grades_visible) {
      this.showHalfGrades();
    } else {
      this.hideHalfGrades();
    }

    this.update(formValueGrades);
    this.save();
  }

  private buildEntities(): Array<StudentRatingSystemGradeEntity> {
    return Array.from(this.records.entries())
      .map(([key, entry]) => ({
        grade: key,
        label: entry.label,
        value: entry.value,
        prefix: entry.prefix,
        isVisible: entry.isVisible,
        isActive: entry.isActive,
        checkboxAvailable: entry.checkboxAvailable,
      }))
      .filter((entity) => entity.isVisible);
  }

  buildFromCopy(): void {
    this.records = new Map(structuredClone(this.copy));

    this.entities$.next(this.buildEntities());
  }
}
