<svg
  class="progress-ring-background"
  [class.progress-ring-background--shadow]="shadowEnabled"
  [attr.height]="diameter"
  [attr.width]="diameter"
>
  <defs>
    <radialGradient id="quiz-ring-gradient" cx="50%" cy="50%" r="50%">
      <stop offset="85%" stop-color="#bbb" />
      <stop offset="90%" stop-color="#ddd" />
      <stop offset="100%" stop-color="#eee" />
    </radialGradient>
  </defs>

  <circle
    *ngIf="showBackground"
    class="progress-ring-background-circle"
    [ngClass]="'theme-' + backgroundColor"
    [attr.stroke-width]="thickness"
    fill="transparent"
    [attr.r]="radius"
    [attr.cx]="diameter / 2"
    [attr.cy]="diameter / 2"
  />
</svg>

<span class="progress-text paragraph">
  <ng-content></ng-content>
</span>

<svg class="progress-ring" [attr.height]="diameter" [attr.width]="diameter">
  <circle
    class="progress-ring-circle"
    [ngClass]="'theme-' + theme"
    [attr.stroke-width]="thickness"
    fill="transparent"
    [attr.r]="radius"
    [attr.cx]="diameter / 2"
    [attr.cy]="diameter / 2"
  />
</svg>
