import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PdfReport } from "src/app/shared/interfaces/pdfreport";

export const organizationRaportPrefix = "[Organization Raport]";

const organizationRaportActions = {
  request: `${organizationRaportPrefix} Load`,
  requestSuccess: `${organizationRaportPrefix} Load success`,
  requestFail: `${organizationRaportPrefix} Load fail`,
};

export const organizationRaportLoad = createAction(
  organizationRaportActions.request,
  props<{ OrganizationId: string; OrganizationName: string }>(),
);

export const organizationRaportLoadSuccess = createAction(
  organizationRaportActions.requestSuccess,
  props<{ pdf: PdfReport }>(),
);

export const organizationRaportLoadFail = createAction(
  organizationRaportActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
