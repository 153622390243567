import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { delay, take } from "rxjs/operators";
import { Member } from "../../../interfaces/member";
import { Store } from "@ngrx/store";
import * as fromAccountStore from "../../../../store/reducers/account.reducer";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ModalComponent } from "../../modal/modal.component";

@Component({
  selector: "quiz-solve-confirm-modal",
  templateUrl: "./quiz-solve-confirm-modal.component.html",
  styleUrls: ["./quiz-solve-confirm-modal.component.scss"],
})
export class QuizSolveConfirmModalComponent
  extends ModalComponent
  implements OnInit
{
  pass_mark = 24100;
  points_max = 1567;
  quiz_points = 16780;

  required_percentage = "0%";
  points_percentage = "0%";

  progressFlow$ = of(true).pipe(delay(10), take(1));

  private readonly member$: Observable<Member> = this.store.select(
    (state) => state.account.member,
  );
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<{ account: fromAccountStore.AccountState }>,
  ) {
    super();
  }

  get thresholdPercentage(): string {
    return (
      (this.data.request.quiz.pass_mark / this.data.request.quiz.points_max) *
      100
    )
      .toFixed()
      .toString();
  }

  ngOnInit(): void {
    if (this.data.result) {
      this.required_percentage = `${Math.round(
        (this.data.request.quiz.pass_mark / this.data.request.quiz.points_max) *
          100,
      )}%`;

      this.points_percentage = `${Math.round(
        (this.data.result.quiz_points / this.data.request.quiz.points_max) *
          100,
      )}%`;
    }
  }

  closeConfirm(): void {
    this.member$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((member) => {
        member.is_teacher
          ? this.goBackToTeacherView()
          : this.goBackToStudentView();

        this.close.emit("close");
      });
  }

  private goBackToTeacherView(): void {
    const { CourseId, TopicId } = this.data.request;
    const groupId = this.route.snapshot.queryParamMap.get("returnGroupId");

    this.router.navigate(
      [`/teacher/courses/${CourseId}${groupId ? `/groups/${groupId}` : ""}`],
      {
        queryParams: { topicId: TopicId },
      },
    );
  }

  private goBackToStudentView(): void {
    const { GroupId, CourseId, TopicId } = this.data.request;

    this.router.navigate([`/student/courses/${CourseId}/groups/${GroupId}`], {
      queryParams: { topicId: TopicId },
    });
  }
}
