import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { PdfReport } from "src/app/shared/interfaces/pdfreport";
import {
  organizationRaportLoad,
  organizationRaportLoadFail,
  organizationRaportLoadSuccess,
} from "../../actions/admin/organization-raport.actions";
import { RequestStatus } from "src/app/shared/interfaces/request-status";

export interface OrganizationReportState {
  pdf: PdfReport;
  error: HttpErrorResponse | null;
  status: RequestStatus;
}

const initialState: OrganizationReportState = {
  pdf: null,
  error: null,
  status: "IDLE",
};

export const organizationReportReducer = createReducer(
  initialState,
  on(
    organizationRaportLoad,
    (state): OrganizationReportState => ({
      ...state,
      pdf: null,
      error: null,
      status: "PENDING",
    }),
  ),
  on(
    organizationRaportLoadSuccess,
    (state, action): OrganizationReportState => ({
      ...state,
      pdf: action.pdf,
      error: null,
      status: "SUCCESS",
    }),
  ),
  on(
    organizationRaportLoadFail,
    (state, { error }): OrganizationReportState => ({
      ...state,
      error,
      status: "FAILED",
    }),
  ),
);
