<ng-container
  *ngIf="{
    students: students$ | async,
    activeGroup: activeGroup$ | async
  } as history"
>
  <button
    [disabled]="history.activeGroup && isTeacher && !history.students"
    class="exercise-control-button exercise-control-button--history theme-hprimarydark"
    tooltip="COURSES.COURSE.EXERCISE.HISTORY.BUTTON_TOOLTIP"
    (click)="
      showHistory(
        courseId,
        exercise,
        isTeacher,
        history.activeGroup,
        history.students,
        isTeacher
      )
    "
  >
    <span class="uil uil-history"></span>
  </button>
</ng-container>
