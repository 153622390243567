import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { QuizAnswers } from "../../interfaces/quiz-answers";
import { QuizQuestion } from "../../interfaces/quiz-question";
import { QuizService } from "src/app/shared/services/quiz/quiz.service";
import { Guid } from "src/app/shared/types/guid";
import { distinctUntilChanged, map, take } from "rxjs/operators";

export const MAX_QUIZ_TIME = 86399;
export const MAX_POINTS_PER_EXERCISE = 1;

@Component({
  selector: "quiz-form",
  templateUrl: "./quiz-form.component.html",
  styleUrls: ["./quiz-form.component.scss"],
})
export class QuizFormComponent implements OnInit {
  @Input() showQuizFooterWithSubmitButton: boolean = false;
  @Input() quizQuestions: Array<QuizQuestion>;
  @Input() quizAnswerCompleted: boolean;
  @Input() courseId: Guid;
  @Input() groupId: Guid;
  @Input() exerciseId: Guid;

  @Output() formSubmitted = new EventEmitter<QuizAnswers>();
  @Output() resolvedQuestionsCount = new EventEmitter<number>();
  @Output() resolvedQuestions = new EventEmitter<
    Array<{ id: number; answer: Array<boolean> }>
  >();

  quizForm: UntypedFormGroup;
  maxPoints = MAX_POINTS_PER_EXERCISE;

  constructor(
    private fb: UntypedFormBuilder,
    private quizService: QuizService,
  ) {}

  ngOnInit() {
    this.prepareQuizForm();
    this.getFigures();
    this.listenToAnsweredQuestionsChanges();
  }

  submit(): void {
    const answers: QuizAnswers = {
      answers: this.quizForm.value.answers.map((item) => ({
        id: item.id,
        answer: this.mapAnswersToNumber(item.answer),
      })),
    };

    this.formSubmitted.emit(answers);
  }

  mapAnswersToNumber(input: boolean[]): number[] {
    return input
      .map((value, index) => (value ? index + 1 : null))
      .filter((value) => value !== null);
  }

  isAllAnswered(): boolean {
    return (this.quizForm.value as QuizAnswers).answers.every(
      (item) => item.answer.length,
    );
  }

  private prepareQuizForm() {
    this.quizForm = this.fb.group({
      answers: this.fb.array(this.prepareAnswers(), Validators.required),
    });
  }

  private prepareAnswers(): Array<UntypedFormGroup> {
    return this.quizQuestions.map((question) =>
      this.fb.group({
        id: this.fb.control(question.id),
        answer: this.fb.array(
          question.answer_set.map(() => this.fb.control(false)),
        ),
      }),
    );
  }

  private getFigures(): void {
    this.quizQuestions.forEach((question) => {
      if (question.has_figure) {
        this.quizService
          .getFigure({
            CourseId: this.courseId,
            GroupId: this.groupId,
            ExerciseId: this.exerciseId,
            QuestionId: question.id,
          })
          .pipe(take(1))
          .subscribe((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = () => {
              question.figure = reader.result as string;
              question.figure_ready = true;
            };
          });
      }
    });
  }

  private listenToAnsweredQuestionsChanges(): void {
    this.quizForm.valueChanges
      .pipe(
        map((value) => this.answeredQuestionsCountBasedOnUserAnswers(value)),
        distinctUntilChanged(),
      )
      .subscribe({
        next: (count) => {
          this.resolvedQuestionsCount.emit(count.length);
          this.resolvedQuestions.emit(count);
        },
      });
  }

  private answeredQuestionsCountBasedOnUserAnswers(value: {
    answers: Array<{ id: number; answer: Array<boolean> }>;
  }): Array<{ id: number; answer: Array<boolean> }> {
    const { answers = [] } = value;

    return answers.filter((answer) => answer.answer.includes(true));
  }
}
