import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(private http: HttpClient) {}

  figureCache: { [url: string]: Blob } = {};

  parseName(value: string) {
    return value
      .toLowerCase()
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
      .trim()
      .replace(/\s\s+/g, " ")
      .replace(/ /g, "-");
  }

  download(file: any, fileName = "file.txt", fileType = "application/json") {
    let blob: Blob = new Blob([file], {
      type: fileType,
    });

    let url = window.URL.createObjectURL(blob);
    let anchorNode = document.createElement("a");

    anchorNode.href = url;
    anchorNode.download = fileName;

    document.body.appendChild(anchorNode),
      anchorNode.click(),
      window.URL.revokeObjectURL(url),
      window.setTimeout(function () {
        anchorNode.remove();
      }, 100);
  }

  downloadS3File(url: string, filename: string, onComplete?: () => void): void {
    this.http.get(url, { responseType: "blob" }).subscribe({
      next: (blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(downloadUrl);
        anchor.remove();
      },
      error: () => onComplete && onComplete(),
      complete: () => onComplete && onComplete(),
    });
  }
}
