<modal-overlay>
  <modal-box>
    <div class="settings">
      <div class="settings__header">
        <h3>{{ "QUIZ.QUIZ_SETTINGS" | translate }}</h3>
        <p class="settings__desc">{{ "QUIZ.SETTINGS.DESC" | translate }}</p>
      </div>
      <form [formGroup]="form" class="form">
        <div class="settings__form mt-4">
          <input
            type="checkbox"
            id="time_limit"
            [formControl]="timeLimitControl"
            class="pass-course quiz-answer multi-choice no-bg"
          />
          <label
            class="pass-course--label checkbox d-flex flex-column"
            for="time_limit"
          >
            {{ "QUIZ.SETTINGS.TIME_LIMIT" | translate }}
            <span class="settings__input-desc">
              {{ "QUIZ.SETTINGS.TIME_LIMIT_DESC" | translate }}
            </span>
          </label>

          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <input
              class="settings__input"
              type="number"
              formControlName="time_limit"
            />
            <span class="settings__input-desc mt-2">
              {{ "QUIZ.SETTINGS.TIME_SUBLABEL" | translate }}
            </span>
          </div>
        </div>

        <div class="settings__form mt-4">
          <input
            type="checkbox"
            id="max_attempts"
            [formControl]="maxAttemptsControl"
            class="pass-course quiz-answer multi-choice no-bg"
          />
          <label
            class="pass-course--label checkbox d-flex flex-column"
            for="max_attempts"
          >
            {{ "QUIZ.SETTINGS.MAX_ATTEMPTS" | translate }}

            <span class="settings__input-desc">
              {{ "QUIZ.SETTINGS.MAX_ATTEMPTS_DESC" | translate }}
            </span>
          </label>

          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <input
              class="settings__input"
              type="number"
              formControlName="max_attempts"
            />
            <span class="settings__input-desc mt-2">
              {{ "QUIZ.SETTINGS.INTEGER_SUBLABEL" | translate }}
            </span>
          </div>
        </div>

        <div class="settings__form mt-4">
          <input
            type="checkbox"
            id="pass_mark"
            [formControl]="passMarkControl"
            class="pass-course quiz-answer multi-choice no-bg"
          />
          <label
            class="pass-course--label checkbox d-flex flex-column"
            for="pass_mark"
          >
            {{ "QUIZ.SETTINGS.PASS_MARK" | translate }}

            <span class="settings__input-desc">
              {{ "QUIZ.SETTINGS.PASS_MARK_DESC" | translate }}
            </span>
          </label>

          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <input
              class="settings__input"
              type="number"
              formControlName="pass_mark"
            />
            <span class="settings__input-desc mt-2">
              {{ "QUIZ.SETTINGS.MAX_POINTS" | translate }}
              {{ data.exercise.quiz_properties.max_points }}
            </span>
            <span class="settings__input-desc">
              {{ "QUIZ.SETTINGS.INTEGER_SUBLABEL" | translate }}
            </span>
          </div>
        </div>

        <div
          *ngIf="data.exercise.quiz_properties?.can_toggle_solutions"
          class="settings__form mt-4"
        >
          <input
            type="checkbox"
            id="history"
            [formControl]="historyControl"
            class="pass-course quiz-answer multi-choice no-bg"
          />
          <label
            class="pass-course--label checkbox d-flex flex-column"
            for="history"
          >
            {{
              "COURSES.COURSE.EXERCISE.SELECT_UNSELECT_SOLUTIONS" | translate
            }}

            <span class="settings__input-desc">
              {{ "QUIZ.SETTINGS.HISTORY" | translate }}
            </span>

            <div class="mt-3">
              <input
                type="checkbox"
                id="selected_answers"
                [formControl]="selectedAnswersControl"
                class="pass-course quiz-answer multi-choice no-bg"
              />
              <label
                [ngClass]="{ 'disabled-input': !historyControl.value }"
                class="pass-course--label checkbox d-flex flex-column mb-3"
                for="selected_answers"
              >
                {{
                  "COURSES.COURSE.EXERCISE.SELECT_UNSELECT_SELECTED_ANSWERS"
                    | translate
                }}
              </label>

              <input
                type="checkbox"
                id="correct_answers"
                [formControl]="correctAnswersControl"
                class="pass-course quiz-answer multi-choice no-bg"
              />
              <label
                [ngClass]="{ 'disabled-input': !historyControl.value }"
                class="pass-course--label checkbox d-flex flex-column"
                for="correct_answers"
              >
                {{
                  "COURSES.COURSE.EXERCISE.SELECT_UNSELECT_CORRECT_ANSWERS"
                    | translate
                }}
              </label>
            </div>
          </label>
        </div>
      </form>

      <div class="settings__buttons mt-5">
        <button class="btn-blue-light-600 w-50" (click)="closeModal()">
          {{ "GLOBAL.CANCEL_BUTTON" | translate }}
        </button>
        <button
          class="btn-primary-900 w-50"
          [disabled]="form.invalid"
          (click)="sendForm()"
        >
          {{ "GROUPS.EDIT.EDIT_GROUP_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </modal-box>
</modal-overlay>
