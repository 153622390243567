<div>
  <div class="d-flex" [ngClass]="'justify-content-' + position">
    <button
      class="course-exercise-solution d-flex align-items-center mb-1"
      (click)="viewExerciseSolution()"
    >
      {{ "COURSES.COURSE.EXERCISE.SHOW_SOLUTION" | translate | uppercase }}
      <div
        [ngClass]="{
          'course-exercise-solution-open': isExerciseSolution
        }"
      >
        <icon
          class="course-exercise-solution-icon"
          set="uil"
          name="angle-down"
        />
      </div>
    </button>
  </div>

  <div *ngIf="isExerciseSolution">
    <div class="editor-navbar d-flex justify-content-end">
      <button class="editor-copy" (click)="copyCode()">
        {{ "GLOBAL.COPY" | translate | uppercase }}
      </button>
    </div>
    <ngx-monaco-editor
      [options]="editorOptions"
      [(ngModel)]="answer"
      (onInit)="onEditorInit($event)"
    ></ngx-monaco-editor>
  </div>
</div>
