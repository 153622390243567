<div class="table" *ngIf="rows.length; else noData">
  <div class="table__header">
    <h5 class="p-1">{{ tableHeader | translate }}</h5>
  </div>
  <div class="table__columns d-flex flex-row">
    <p
      class="table__column"
      *ngFor="let col of columns"
      [style.width.%]="col.columnWidth"
    >
      <span [ngClass]="{ 'column-course': col.key == 'tooltip' }">
        {{ col.title | translate }}
      </span>
    </p>
  </div>
  <div
    class="table__rows d-flex flex-row align-items-center overflow-hidden"
    [ngStyle]="{ height: teacherTable ? '70px' : '80px' }"
    *ngFor="let row of rows"
  >
    <p
      class="table__row"
      *ngFor="let column of columns"
      [style.width.%]="column.columnWidth"
    >
      <ng-container [ngSwitch]="column.key">
        <ng-container *ngSwitchCase="'email'">
          <div
            [ngClass]="{
              'table__email-row':
                teacherTable && row.status === StatusValues.ACTIVE
            }"
            (click)="emitRowValue(row)"
          >
            {{ column.value(row) ? column.value(row) : "-" }}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'name'">
          <div
            [ngClass]="{
              'table__email-row':
                teacherTable && row.status === StatusValues.ACTIVE
            }"
            (click)="emitRowValue(row)"
          >
            <circle-badge
              *ngIf="column.value(row) && column.value(row) !== ' '"
              class="pr-2"
              [badgeData]="column.value(row) | shortName"
            />
            {{
              column.value(row) && column.value(row) !== " "
                ? column.value(row)
                : "-"
            }}
          </div>
        </ng-container>

        <div *ngSwitchCase="'group_name'">
          <div class="d-flex flex-column">
            <div class="table__group">
              {{ column.value(row)[0] }}
            </div>
            <div class="table__group" [tooltip]="column.value(row)[1]">
              {{ column.value(row)[1] }}
            </div>
          </div>
        </div>
        <ng-container *ngSwitchCase="'status'">
          <div class="d-flex justify-content-between">
            <status-chip [statusValue]="column.value(row)" />

            <round-button
              *ngIf="deleteBtn && row.status !== StatusValues.ACTIVE"
              class="hprimarydark pr-2"
              set="uil"
              icon="trash-alt"
              tooltip="GROUPS.EDIT.DELETE_BUTTON"
              position="bottom"
              (click)="emitActionValue(row)"
            />
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'tooltip'">
          <div class="d-flex justify-content-end">
            <round-button
              set="uil"
              icon="info-circle"
              class="hprimarydark"
              (click)="toggleTooltip(row)"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
            />

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="isOpen && currentRow == row"
            >
              <div class="table__overlay-courses">
                <div
                  class="d-flex flex-row justify-content-between align-items-baseline"
                  *ngFor="let data of column.value(row)"
                >
                  <p>{{ data.course?.name }}</p>

                  <div class="d-flex flex-row align-items-center course-row">
                    <round-button
                      *ngIf="
                        data.status !== StatusValues.ACTIVE &&
                        data.status !== StatusValues.NONE
                      "
                      class="hprimarydark pr-2 table__icon"
                      set="uil"
                      icon="trash-alt"
                      tooltip="GROUPS.EDIT.DELETE_BUTTON"
                      position="bottom"
                      (click)="emitTooltipValue(data)"
                    />

                    <div
                      class="table__circle"
                      [style.background-color]="
                        (data.status | iconStatus).backgroundColor
                      "
                      [tooltip]="(data.status | iconStatus).value"
                    >
                      <icon
                        class="table__icon"
                        [set]="(data.status | iconStatus).set"
                        [name]="(data.status | iconStatus).icon"
                        [style.color]="(data.status | iconStatus).color"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'actions'">
          <div
            class="d-flex flex-row justify-content-center align-items-center"
          >
            <button
              class="table__accept"
              (click)="emitRowValue(row)"
              tooltip="COURSES.COURSE.RESOLVE_SUBMISSION"
            >
              <icon class="table__accept-icon" set="uil" name="check"></icon>
            </button>
            <div class="ml-3" tooltip="COURSES.COURSE.SUBMISSION_INFO">
              <icon set="uil" name="question-circle" />
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ column.value(row) }}
        </ng-container>
      </ng-container>
    </p>
  </div>
</div>

<ng-template #noData>
  <p class="no-data">
    {{ "GLOBAL.NO_DATA_TO_SHOW" | translate }}
  </p>
</ng-template>
