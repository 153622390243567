import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import { TeacherExerciseFileUploadApiService } from "../../../services/exercise/teacher-exercise-file-upload-api.service";
import { Guid } from "../../../types/guid";
import { CourseExerciseAnswerTableSortKey } from "../../course/course-exercise-answer-table/types/course-exercise-answer-table-sort-key";
import { orderBy } from "lodash-es";
import { CourseExerciseAnswerListModalDataService } from "./services/course-exercise-answer-list-modal-data.service";
import { SortOrder } from "../../../lodash/enums/sort-order";
import { AnswerResponse } from "../../../interfaces/answer-response";
import { FileService } from "../../../services/file/file.service";
import { timer } from "rxjs";
import { switchMap, takeWhile, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "course-exercise-answer-list-modal",
  templateUrl: "./course-exercise-answer-list-modal.component.html",
  styleUrls: ["./course-exercise-answer-list-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseExerciseAnswerListModalComponent
  extends ModalComponent
  implements AfterViewInit
{
  readonly collection$ = this.dialog.data$;
  bulkLoader: boolean = false;
  actualAnswerId: string | null = null;

  constructor(
    private readonly dialog: CourseExerciseAnswerListModalDataService,
    private readonly api: TeacherExerciseFileUploadApiService,
    private readonly fileService: FileService,
    private readonly cdr: ChangeDetectorRef,
    private http: HttpClient,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.fetch();
  }

  handleSort(data: {
    key: CourseExerciseAnswerTableSortKey;
    order: SortOrder;
  }): void {
    const collection = this.dialog.data;

    switch (data.key) {
      case "filename": {
        this.dialog.patch(this.orderBy(collection, "filename", data.order));

        break;
      }

      case "uploadedAt": {
        this.dialog.patch(this.orderBy(collection, "uploaded_at", data.order));

        break;
      }

      case "username": {
        this.dialog.patch(this.orderBy(collection, "user.name", data.order));

        break;
      }
    }
  }

  handleCloseClick(): void {
    this.hideOnUi();
  }

  handleDownload(data: { id: Guid; filename: string }): void {
    const { courseId, exerciseId, groupId } = this.data;

    this.api
      .download({ courseId, exerciseId, fileId: data.id, groupId })
      .pipe(
        tap((response) => {
          this.updateState(false, data.id);

          if (response.s3_url) {
            this.fileService.downloadS3File(
              response.s3_url,
              response.filename,
              () => {
                this.updateState(false, null);
              },
            );
          }
        }),
      )
      .subscribe();
  }

  handleBulkDownload(): void {
    const { courseId, exerciseId, groupId } = this.data;

    timer(0, 4000)
      .pipe(
        switchMap(() =>
          this.api.downloadBulk({ courseId, exerciseId, groupId }),
        ),
        tap((response) => {
          this.updateState(true, null);

          if (response.s3_url) {
            this.fileService.downloadS3File(
              response.s3_url,
              response.filename,
              () => {
                this.updateState(false, null);
              },
            );
          }
        }),
        takeWhile((response) => response.s3_url == null),
      )
      .subscribe();
  }

  handleFailAnswer(fileId: Guid): void {
    const { courseId, exerciseId, groupId } = this.data;

    this.api
      .fail({ courseId, exerciseId, fileId, groupId })

      .subscribe(() => this.fetch());
  }

  handlePassAnswer(fileId: Guid): void {
    const { courseId, exerciseId, groupId } = this.data;

    this.api
      .pass({ courseId, exerciseId, fileId, groupId })
      .subscribe(() => this.fetch());
  }

  private fetch(): void {
    const { courseId, exerciseId, groupId } = this.data;

    this.api
      .collection({ courseId, exerciseId, groupId })
      .subscribe((collection) => {
        this.dialog.patch(collection);
      });
  }

  private hideOnUi(): void {
    this.close.emit("close");
  }

  private orderBy(
    collection: AnswerResponse[],
    key: string,
    order: SortOrder,
  ): AnswerResponse[] {
    return orderBy(collection, key, order);
  }

  private updateState(isLoading: boolean, id: string | null): void {
    this.actualAnswerId = id;
    this.bulkLoader = isLoading;
    this.cdr.detectChanges();
  }
}
