<div class="table-container">
  <table class="flagged-list">
    <thead>
      <tr *ngIf="topicName">
        <th class="flagged-list__head flagged-list__title" colspan="3">
          <span class="ml-4">{{ topicName }}</span>
        </th>
      </tr>
      <tr class="flagged-list__head flagged-list__title">
        <th>
          <span class="ml-4">{{ "RANKING.TABLE.NAME" | translate }}</span>
        </th>
        <th>
          <span class="ml-4">
            {{ "COURSES.COURSE.REPORT_DATE" | translate }}
          </span>
        </th>
        <th>
          <span class="ml-4">
            {{ "COURSES.COURSE.RESOLVED_DATE" | translate }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let exercise of helpRequested.data">
        <tr>
          <td class="flagged-list__head flagged-list__title" colspan="3">
            <span class="ml-4">
              {{ exercise.exercise.name }}
            </span>
          </td>
        </tr>
        <tr
          class="flagged-list__body flagged-list__name"
          *ngFor="let request of exercise.help_requested"
        >
          <td class="flagged-list__body-cell">
            <span class="ml-4">
              {{ request.user.name ? request.user.name : request.user.email }}
            </span>
          </td>
          <td class="flagged-list__body-cell">
            <span class="ml-4">
              {{ request.created_at | date }}
            </span>
          </td>
          <td class="flagged-list__body-cell">
            <span class="ml-4">
              {{ request.resolved_at ? (request.resolved_at | date) : "-" }}
            </span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
