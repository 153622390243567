import { ExerciseStatus } from "../../enums/exercise-status";
import { AnswerType } from "../../types/answer-type";

export const EXERCISE_ANSWER_CONFIG = {
  [AnswerType.ANSWER_TYPE_QUIZ]: {
    [ExerciseStatus.NEW]: { kind: "gray", theme: "light", icon: "list-ol-alt" },
    [ExerciseStatus.UNCOMPLETED]: {
      kind: "gray",
      theme: "light",
      icon: "list-ol-alt",
    },
    [ExerciseStatus.COMPLETED]: { icon: "list-ol-alt" },
    [ExerciseStatus.FAILED]: {
      kind: "error",
      icon: "list-ol-alt",
      theme: "light",
    },
    [ExerciseStatus.STARTED]: {
      kind: "warning",
      theme: "light",
      icon: "stopwatch",
    },
    [ExerciseStatus.INDIVIDUAL]: {
      kind: "gray",
      theme: "light",
      icon: "plus-circle",
    },
  },
  [AnswerType.ANSWER_TYPE_NONE]: {
    [ExerciseStatus.NEW]: { kind: "gray", theme: "light", icon: "check" },
    [ExerciseStatus.UNCOMPLETED]: {
      kind: "gray",
      theme: "light",
      icon: "check",
    },
    [ExerciseStatus.COMPLETED]: { icon: "check" },
    [ExerciseStatus.FAILED]: { kind: "error", icon: "check", theme: "light" },
    [ExerciseStatus.STARTED]: {
      kind: "warning",
      theme: "light",
      icon: "stopwatch",
    },
    [ExerciseStatus.INDIVIDUAL]: {
      kind: "gray",
      theme: "light",
      icon: "plus-circle",
    },
  },
  [AnswerType.ANSWER_TYPE_CODE]: {
    [ExerciseStatus.NEW]: { kind: "gray", theme: "light", icon: "arrow" },
    [ExerciseStatus.UNCOMPLETED]: {
      kind: "gray",
      theme: "light",
      icon: "arrow",
    },
    [ExerciseStatus.COMPLETED]: { icon: "arrow" },
    [ExerciseStatus.FAILED]: { kind: "error", icon: "arrow", theme: "light" },
    [ExerciseStatus.STARTED]: {
      kind: "warning",
      theme: "light",
      icon: "stopwatch",
    },
    [ExerciseStatus.INDIVIDUAL]: {
      kind: "gray",
      theme: "light",
      icon: "plus-circle",
    },
  },
  [AnswerType.ANSWER_TYPE_CONTAINER]: {
    [ExerciseStatus.NEW]: { kind: "gray", theme: "light", icon: "arrow" },
    [ExerciseStatus.UNCOMPLETED]: {
      kind: "gray",
      theme: "light",
      icon: "arrow",
    },
    [ExerciseStatus.COMPLETED]: { icon: "arrow" },
    [ExerciseStatus.FAILED]: { kind: "error", icon: "arrow", theme: "light" },
    [ExerciseStatus.STARTED]: {
      kind: "warning",
      theme: "light",
      icon: "stopwatch",
    },
    [ExerciseStatus.INDIVIDUAL]: {
      kind: "gray",
      theme: "light",
      icon: "plus-circle",
    },
  },
  [AnswerType.ANSWER_TYPE_FILE_UPLOAD]: {
    [ExerciseStatus.NEW]: { kind: "gray", theme: "light", icon: "file-upload" },
    [ExerciseStatus.UNCOMPLETED]: {
      kind: "gray",
      theme: "light",
      icon: "file-upload",
    },
    [ExerciseStatus.COMPLETED]: { icon: "file-upload" },
    [ExerciseStatus.FAILED]: {
      kind: "error",
      icon: "file-upload",
      theme: "light",
    },
    [ExerciseStatus.STARTED]: {
      kind: "warning",
      theme: "light",
      icon: "stopwatch",
    },
    [ExerciseStatus.INDIVIDUAL]: {
      kind: "gray",
      theme: "light",
      icon: "plus-circle",
    },
  },
  [AnswerType.ANSWER_TYPE_UPLOAD]: {
    [ExerciseStatus.NEW]: { kind: "gray", theme: "light", icon: "file-upload" },
    [ExerciseStatus.UNCOMPLETED]: {
      kind: "gray",
      theme: "light",
      icon: "file-upload",
    },
    [ExerciseStatus.COMPLETED]: { icon: "file-upload" },
    [ExerciseStatus.FAILED]: {
      kind: "error",
      icon: "file-upload",
      theme: "light",
    },
    [ExerciseStatus.STARTED]: {
      kind: "warning",
      theme: "light",
      icon: "stopwatch",
    },
    [ExerciseStatus.INDIVIDUAL]: {
      kind: "gray",
      theme: "light",
      icon: "plus-circle",
    },
  },
  [AnswerType.ANSWER_TYPE_FLAG]: {
    [ExerciseStatus.NEW]: {
      kind: "gray",
      theme: "light",
      icon: "server-network",
    },
    [ExerciseStatus.UNCOMPLETED]: {
      kind: "gray",
      theme: "light",
      icon: "server-network",
    },
    [ExerciseStatus.COMPLETED]: { icon: "server-network" },
    [ExerciseStatus.FAILED]: {
      kind: "error",
      icon: "server-network",
      theme: "light",
    },
    [ExerciseStatus.STARTED]: {
      kind: "warning",
      theme: "light",
      icon: "stopwatch",
    },
    [ExerciseStatus.INDIVIDUAL]: {
      kind: "gray",
      theme: "light",
      icon: "plus-circle",
    },
  },
};
