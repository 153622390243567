import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { FlaggedExercise } from "src/app/shared/interfaces/flagged-exercises";
import {
  flaggedExerciseHistoryListLoad,
  flaggedExerciseHistoryListLoadSuccess,
  flaggedExerciseHistoryListFailure,
} from "../../actions/flagged-exercises/get-flagged-history-exercises.actions";

export interface FlaggedExerciseHistoryListState {
  exercises: FlaggedExercise[] | null;
  error: HttpErrorResponse | null;
}

const initialState: FlaggedExerciseHistoryListState = {
  exercises: null,
  error: null,
};

export const flaggedExerciseHistoryListReducer = createReducer(
  initialState,
  on(
    flaggedExerciseHistoryListLoad,
    (state): FlaggedExerciseHistoryListState => ({
      ...state,
      exercises: null,
      error: null,
    }),
  ),
  on(
    flaggedExerciseHistoryListLoadSuccess,
    (state, action): FlaggedExerciseHistoryListState => ({
      ...state,
      exercises: action.exercises,
      error: null,
    }),
  ),
  on(flaggedExerciseHistoryListFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
