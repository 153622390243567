import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MappedHelpRequests } from "../../interfaces/mapped-help-requests";

@Component({
  selector: "student-flagged-exercises-list-table",
  templateUrl: "./student-flagged-exercises-list-table.component.html",
  styleUrls: ["./student-flagged-exercises-list-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentFlaggedExercisesListTableComponent {
  @Input() helpRequested: { data: MappedHelpRequests[] };
  @Input() topicName: string;
}
