<modal-overlay>
  <box class="no-paddings">
    <div class="row no-gutters flex-column">
      <div class="col-12 mb-5">
        <course-exercise-answer-table
          [answers]="collection$ | async"
          [bulkLoader]="bulkLoader"
          [actualAnswerId]="actualAnswerId"
          (downloadEvent)="handleDownload($event)"
          (downloadBulkEvent)="handleBulkDownload()"
          (failAnswerEvent)="handleFailAnswer($event)"
          (passAnswerEvent)="handlePassAnswer($event)"
          (sortEvent)="handleSort($event)"
        />
      </div>

      <div class="col-12">
        <div class="d-flex justify-content-end p-4">
          <button
            class="theme-hprimarydark px-5 ml-3"
            (click)="handleCloseClick()"
          >
            {{ "GLOBAL.CLOSE_BUTTON" | translate }}
          </button>
        </div>
      </div>
    </div>
  </box>
</modal-overlay>
