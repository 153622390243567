<ul class="answer-list">
  <li *ngFor="let answer of question.answer_set; let idx = index">
    <input
      type="checkbox"
      class="quiz-answer multi-choice"
      id="{{ question.id }}_{{ answer.id }}"
      [formControl]="formArray.at(idx)"
      [disabled]="isDisabled"
      (change)="toggleSingleSelection(idx)"
    />
    <label for="{{ question.id }}_{{ answer.id }}" class="checkbox answer">
      {{ answer.content | booleanString | translate }}
    </label>
  </li>
</ul>
