import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from "@angular/core";
import { QuizAnswerComponent } from "../quiz-answer/quiz-answer.component";
import { FormArray, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "quiz-answer-choice",
  templateUrl: "./quiz-answer-choice.component.html",
  styleUrls: [
    "./quiz-answer-choice.component.scss",
    "../quiz-answer/quiz-answer.component.scss",
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuizAnswerChoiceComponent),
      multi: true,
    },
  ],
})
export class QuizAnswerChoiceComponent extends QuizAnswerComponent {
  @Input() formArray: FormArray;

  constructor() {
    super();
  }

  toggleSingleSelection(index: number): void {
    if (!this.question.multi_choice) {
      this.formArray.controls.forEach((control, i) => {
        if (i !== index) {
          control.setValue(false);
        }
      });
    }
  }
}
