import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { QuizQuestion } from "../../interfaces/quiz-question";

@Component({
  selector: "quiz-answer",
  template: ``,
  styleUrls: ["./quiz-answer.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuizAnswerComponent),
      multi: true,
    },
  ],
})
export class QuizAnswerComponent implements OnInit, ControlValueAccessor {
  @Input() question: QuizQuestion;
  answerTree = {};
  isDisabled = false;

  onChange = (answer: Array<number>) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit(): void {
    this.prepareAnswerTree();
  }

  writeValue(answer: Array<number>): void {
    this.onChange([...answer]);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private prepareAnswerTree() {
    this.question.answer_set.forEach((answer) => {
      this.answerTree[answer.id] = false;
    });
  }
}
